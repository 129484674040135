import Vue from "vue";
import Router from "vue-router";
import Permissions from "@/core/services/permissions.service";
import i18n from "@/core/plugins/vue-i18n";

Vue.use(Router);

const route = new Router({
    mode: 'history',
    routes: [{
            path: "/",
            redirect: "/dashboard",
            component: () =>
                import ("@/view/layout/Layout"),
            children: [{
                    path: "/dashboard",
                    name: "dashboard",
                    component: () =>
                        import ("@/view/pages/modules/dashboard/Index.vue"),
                },
                // ****************************** Begin Users *************************************************
                {
                    path: "/users/users",
                    name: "users.index",
                    component: () =>
                        import ("@/view/pages/modules/users/Index.vue"),
                    meta: { role: ['users.list'] }
                },
                {
                    path: "/users/users/create",
                    name: "users.create",
                    component: () =>
                        import ("@/view/pages/modules/users/Form.vue"),
                    meta: { role: ['users.list'] }
                },
                {
                    path: "/users/users/edit/:id",
                    name: "users.edit",
                    component: () =>
                        import ("@/view/pages/modules/users/Form.vue"),
                    meta: { role: ['users.update'] }
                },
                // ****************************** End Users ***************************************************

                // ****************************** Begin account admin *************************************************
                {
                    path: "/admin_account/setting",
                    name: "admin_accounts.update",
                    component: () =>
                        import ("@/view/pages/modules/adminAccount/Form.vue"),
                    meta: { role: ['admin_accounts.update'] }
                },
                // ****************************** End account admin ***************************************************

                // ****************************** Begin Roles *************************************************
                {
                    path: "/users/roles",
                    name: "roles.index",
                    component: () =>
                        import ("@/view/pages/modules/roles/Index.vue"),
                    meta: { role: ['roles.list'] }
                },
                {
                    path: "/users/roles/edit/:id",
                    name: "roles.edit",
                    component: () =>
                        import ("@/view/pages/modules/roles/Form.vue"),
                    meta: { role: ['roles.update'] }
                },
                // ****************************** End Roles *****************************************************
                // ****************************** Begin Users *****************************************************
                {
                    path: "/company-configuration",
                    name: "company-configuration.index",
                    component: () =>
                        import ("@/view/pages/modules/configuration/company/Form.vue"),
                    // meta:{role:['company-configuration.index']}
                },
                {
                    path: "/user-settings",
                    name: "user-settings.index",
                    component: () =>
                        import ("@/view/pages/modules/configuration/user/Form.vue"),
                    // meta:{role:['company-configuration.index']}
                },

                // ****************************** End Users ***************************************************

                // ****************************** Begin Countries & Cities **********************************
                {
                    path: "/settings/countries",
                    name: "countries.index",
                    component: () =>
                        import ("@/view/pages/modules/settings/countries/Index.vue"),
                    meta: { role: ['countries.list'] }
                },
                {
                    path: "/settings/countries/edit/:id",
                    name: "countries.edit",
                    component: () =>
                        import ("@/view/pages/modules/settings/countries/Form.vue"),
                    meta: { role: ['countries.update'] }
                },

                {
                    path: "/settings/cities/:code2",
                    name: "cities.index",
                    component: () =>
                        import ("@/view/pages/modules/settings/cities/Index.vue"),
                    meta: { role: ['cities.list'] }
                },
                // ****************************** End Countries ***************************************************
                // ****************************** Begin Categories *******************************************
                {
                    path: "/settings/categories",
                    name: "categories.index",
                    component: () =>
                        import ("@/view/pages/modules/settings/categories/Form.vue"),
                    meta: { role: ['categories.list'] }
                },

                // ****************************** End Categories ***********************************************
                // ****************************** Begin module-settings *******************************************
                {
                    path: "/settings/module-settings",
                    name: "module-settings.index",
                    component: () =>
                        import ("@/view/pages/modules/settings/moduleSetting/Index.vue"),
                    meta: { role: ['module_settings.list'] }
                },
                {
                    path: "/settings/module-settings/create",
                    name: "module-settings.create",
                    component: () =>
                        import ("@/view/pages/modules/settings/moduleSetting/Form.vue"),
                    meta: { role: ['module_settings.list'] }
                },
                {
                    path: "/settings/module-settings/edit/:id",
                    name: "module-settings.edit",
                    component: () =>
                        import ("@/view/pages/modules/settings/moduleSetting/Form.vue"),
                    meta: { role: ['module_settings.update'] }
                },
                // ****************************** End module-settings ***********************************************
                // ****************************** Begin module-settings-sub-actions *******************************************
                {
                    path: "/settings/module-settings-sub-actions/:module_id",
                    name: "module-settings-sub-actions.index",
                    component: () =>
                        import ("@/view/pages/modules/settings/moduleSetting/subActions/Index.vue"),
                    meta: { role: ['module_sub_actions.list'] }
                },
                {
                    path: "/settings/module-settings-sub-actions/create/:module_id",
                    name: "module-settings-sub-actions.create",
                    component: () =>
                        import ("@/view/pages/modules/settings/moduleSetting/subActions/Form.vue"),
                    meta: { role: ['module_sub_actions.list'] }
                },
                {
                    path: "/settings/module-settings-sub-actions/edit/:module_id/:id",
                    name: "module-settings-sub-actions.edit",
                    component: () =>
                        import ("@/view/pages/modules/settings/moduleSetting/subActions/Form.vue"),
                    meta: { role: ['module_sub_actions.update'] }
                },
                // ****************************** End module-settings-sub-actions ***********************************************

                // ****************************** Begin business-field *************************************************
                {
                    path: "/settings/business-field",
                    name: "business-field.index",
                    component: () =>
                        import ("@/view/pages/modules/settings/businessField/Index.vue"),
                    meta: { role: ['business_field.list'] }
                },
                // ****************************** End business-field ************************************************************

                // ****************************** Begin register-company *************************************************
                {
                    path: "/settings/register-company",
                    name: "register-company.index",
                    component: () =>
                        import ("@/view/pages/modules/settings/registerCompany/Index.vue"),
                    meta: { role: ['register_company.list'] }
                },
                // ****************************** End register-company ******************************************************

                // ****************************** Begin SyncEndPoint *************************************************
                {
                    path: "/settings/sync-endpoint",
                    name: "sync-endpoint.index",
                    component: () =>
                        import ("@/view/pages/modules/settings/SyncEndPoint/Index.vue"),
                    meta: { role: ['sync_endpoint.list'] }
                },
                // ****************************** End register-company ******************************************************

                // ****************************** Begin plans *******************************************
                {
                    path: "/subscription/plans",
                    name: "plans.index",
                    component: () =>
                        import ("@/view/pages/modules/subscription/plans/Index.vue"),
                    meta: { role: ['plans.list'] }
                },
                {
                    path: "/subscription/plans/create",
                    name: "plans.create",
                    component: () =>
                        import ("@/view/pages/modules/subscription/plans/Form.vue"),
                    meta: { role: ['plans.list'] }
                },
                {
                    path: "/subscription/plans/edit/:id",
                    name: "plans.edit",
                    component: () =>
                        import ("@/view/pages/modules/subscription/plans/Form.vue"),
                    meta: { role: ['plans.update'] }
                },
                {
                    path: "/subscription/plans/details/:id",
                    name: "plans.details",
                    component: () =>
                        import ("@/view/pages/modules/subscription/plans/Details.vue"),
                    meta: { role: ['plans.update'] }
                },
                
                {
                    path: "/subscription/plans/print-setting-management/:plan_id",
                    name: "plans.print-setting-management",
                    component: () =>
                        import ("@/view/pages/modules/subscription/plans/printSettingManagement.vue"),
                    meta: { role: ['plans.update'] }
                },
                {
                    path: "/subscription/plans/invoices-bonds-management/:plan_id",
                    name: "plans.invoices-bonds-management",
                    component: () =>
                        import ("@/view/pages/modules/subscription/plans/invoicesBondsManagement.vue"),
                    meta: { role: ['plans.update'] }
                },
                {
                    path: "/subscription/plans/code-settings/:plan_id",
                    name: "plans.code-settings",
                    component: () =>
                        import ("@/view/pages/modules/subscription/plans/codeSettings.vue"),
                    meta: { role: ['plans.update'] }
                },

                // ****************************** End plans ***********************************************
                // ****************************** Begin request-tenant-subdomain ***********************************************
                {
                    path: "/subscription/request-tenant-subdomain",
                    name: "request-tenant-subdomain.index",
                    component: () =>
                        import ("@/view/pages/modules/subscription/subdomain/Index.vue"),
                    meta: { role: ['requests_tenant.list'] }
                },
                {
                    path: "/subscription/request-tenant-subdomain/details/:id",
                    name: "request-tenant-subdomain.details",
                    component: () =>
                        import ("@/view/pages/modules/subscription/subdomain/View.vue"),
                    meta: { role: ['requests_tenant.list'] }
                },
                // ****************************** End request-tenant-subdomain ***********************************************
                // ****************************** Begin management-subdomain ***********************************************
                {
                    path: "/subscription/management-subdomain",
                    name: "management-subdomain.index",
                    component: () =>
                        import ("@/view/pages/modules/subscription/managementSubdomain/Index.vue"),
                    meta: { role: ['management_subdomains.list'] }
                },
                {
                    path: "/subscription/management-subdomain/details/:id",
                    name: "management-subdomain.details",
                    component: () =>
                        import ("@/view/pages/modules/subscription/managementSubdomain/View.vue"),
                    meta: { role: ['management_subdomains.list'] }
                },
                {
                    path: "/subscription/management-subdomain/permission-management/:plan_id/:company_id",
                    name: "management-subdomain.permission_management",
                    component: () =>
                        import ("@/view/pages/modules/subscription/managementSubdomain/permissionManagement.vue"),
                    meta: { role: ['management_subdomains.list'] }
                },

                {
                    path: "/subscription/management-subdomain/print-setting-management/:plan_id/:company_id",
                    name: "management-subdomain.print-setting-management",
                    component: () =>
                        import ("@/view/pages/modules/subscription/managementSubdomain/printSettingManagement.vue"),
                    meta: { role: ['management_subdomains.list'] }
                },
                {
                    path: "/subscription/management-subdomain/invoices-bonds-management/:plan_id/:company_id",
                    name: "management-subdomain.invoices-bonds-management",
                    component: () =>
                        import ("@/view/pages/modules/subscription/managementSubdomain/invoicesBondsManagement.vue"),
                    meta: { role: ['management_subdomains.list'] }
                },
                {
                    path: "/subscription/management-subdomain/code-settings/:plan_id/:company_id",
                    name: "management-subdomain.code-settings",
                    component: () =>
                        import ("@/view/pages/modules/subscription/managementSubdomain/codeSettings.vue"),
                    meta: { role: ['management_subdomains.list'] }
                },
                
                // ****************************** End management-subdomain ***********************************************
                // ****************************** Begin management-subdomain ***********************************************
                {
                    path: "/subscription/management-database",
                    name: "management-database.index",
                    component: () =>
                        import ("@/view/pages/modules/subscription/managementDatabase/Index.vue"),
                    meta: { role: ['db_managements.list'] }
                },
                {
                    path: "/subscription/management-database/details/:id",
                    name: "management-database.details",
                    component: () =>
                        import ("@/view/pages/modules/subscription/managementDatabase/View.vue"),
                    meta: { role: ['db_managements.list'] }
                },
                // ****************************** End management-subdomain ***********************************************
                // ****************************** Begin required-tenant ***********************************************
                {
                    path: "/subscription/required-tenant",
                    name: "required-tenant.index",
                    component: () =>
                        import ("@/view/pages/modules/subscription/requiredTenant/Index.vue"),
                    meta: { role: ['required_tenants.list'] }
                },
                {
                    path: "/subscription/required-tenant/details/:id",
                    name: "required-tenant.details",
                    component: () =>
                        import ("@/view/pages/modules/subscription/requiredTenant/View.vue"),
                    meta: { role: ['required_tenants.list'] }
                },
                // ****************************** End required-tenant ***********************************************

                // ****************************** Begin reports-subscription ***********************************************
                {
                    path: "/reports/reports-subscription",
                    name: "reports-subscription.index",
                    component: () =>
                        import ("@/view/pages/modules/reports/subscription/Index.vue"),
                    // meta: { role: ['report_subscription.list'] }
                },
                // ****************************** End reports-subscription ***********************************************

                // ****************************** Begin reports-expired-subscription ***********************************************
                {
                    path: "/reports/reports-expired-subscription",
                    name: "reports-expired-subscription.index",
                    component: () =>
                        import ("@/view/pages/modules/reports/expiredSubscription/Index.vue"),
                    // meta: { role: ['report_subscription.list'] }
                },
                // ****************************** End reports-expired-subscription ***********************************************

                // ****************************** Begin payments-moyasar ***********************************************
                {
                    path: "/subscription/payments-moyasar",
                    name: "payments-moyasar.index",
                    component: () =>
                        import ("@/view/pages/modules/subscription/payments/moyasar/Index.vue"),
                    // meta: { role: ['payments_moyasar.list'] }
                },

                // ****************************** End payments-moyasar ***********************************************
                // ****************************** Begin payments-paypal ***********************************************
                {
                    path: "/subscription/payments-paypal",
                    name: "payments-paypal.index",
                    component: () =>
                        import ("@/view/pages/modules/subscription/payments/paypal/Index.vue"),
                    // meta: { role: ['payments_paypal.list'] }
                },
                // ****************************** End payments-paypal ***********************************************

                // ****************************** Begin payments-cash ***********************************************
                 {
                    path: "/subscription/payments-cash",
                    name: "payments-cash.index",
                    component: () =>
                        import ("@/view/pages/modules/subscription/payments/cash/Index.vue"),
                    // meta: { role: ['payments_cash.list'] }
                },
                // ****************************** End payments-cash ***********************************************

                 // ****************************** Begin bank-transfers ***********************************************
                 {
                    path: "/subscription/bank-transfers",
                    name: "bank-transfers.index",
                    component: () =>
                        import ("@/view/pages/modules/subscription/payments/bankTransfer/Index.vue"),
                    // meta: { role: ['payments_cash.list'] }
                },
                // ****************************** End bank-transfers ***********************************************

                // ****************************** Begin invoices ***********************************************
                {
                    path: "/subscription/invoices",
                    name: "invoices.index",
                    component: () =>
                        import ("@/view/pages/modules/subscription/invoices/Index.vue"),
                    // meta: { role: ['invoices.list'] }
                },
                // ****************************** End invoices ***********************************************

                // ****************************** Begin invoices ***********************************************
                {
                    path: "/subscription/reset-company",
                    name: "resetCompany.index",
                    component: () =>
                        import ("@/view/pages/modules/subscription/resetCompany/Form.vue"),
                    meta: { role: ['reset_company.list'] }
                },
                // ****************************** End invoices ***********************************************

                // ****************************** Begin agents ***********************************************
                {
                    path: "/agents/agents",
                    name: "agents.index",
                    component: () =>
                        import ("@/view/pages/modules/agents/agents/Index.vue"),
                    meta: { role: ['agents.list'] }
                },
                {
                    path: "/agents/agents/create",
                    name: "agents.create",
                    component: () =>
                        import ("@/view/pages/modules/agents/agents/Form.vue"),
                    meta: { role: ['agents.create'] }
                },
                {
                    path: "/agents/agents/edit/:id",
                    name: "agents.update",
                    component: () =>
                        import ("@/view/pages/modules/agents/agents/Form.vue"),
                    meta: { role: ['agents.update'] }
                },

                // ****************************** End agents ***********************************************
                // ****************************** Begin commission ***********************************************
                {
                    path: "/agents/commission",
                    name: "commission.index",
                    component: () =>
                        import ("@/view/pages/modules/agents/commission/Index.vue"),
                    // meta: { role: ['commission.list'] }
                },
                {
                    path: "/agents/commission/create/:agent_id?",
                    name: "commission.create",
                    component: () =>
                        import ("@/view/pages/modules/agents/commission/Form.vue"),
                    // meta: { role: ['commission.create'] }
                },
                {
                    path: "/agents/commission/edit/:id",
                    name: "commission.update",
                    component: () =>
                        import ("@/view/pages/modules/agents/commission/Form.vue"),
                    // meta: { role: ['commission.update'] }
                },

                // ****************************** End commission ***********************************************
                // ****************************** Begin agents-customers ***********************************************
                {
                    path: "/agents/agents-customers",
                    name: "agents-customers.index",
                    component: () =>
                        import ("@/view/pages/modules/agents/agentsCustomers/Index.vue"),
                    // meta: { role: ['agents_customers.list'] }
                },

                // ****************************** End agents-customers ***********************************************
                // ****************************** Begin apply-commission ***********************************************
                {
                    path: "/agents/apply-commission",
                    name: "apply-commission.index",
                    component: () =>
                        import ("@/view/pages/modules/agents/applyCommission/Index.vue"),
                    // meta: { role: ['apply_commission.list'] }
                },
                {
                    path: "/agents/apply-commission/create",
                    name: "apply-commission.create",
                    component: () =>
                        import ("@/view/pages/modules/agents/applyCommission/Form.vue"),
                    // meta: { role: ['apply_commission.create'] }
                },
                {
                    path: "/agents/apply-commission/edit/:id",
                    name: "apply-commission.update",
                    component: () =>
                        import ("@/view/pages/modules/agents/applyCommission/Form.vue"),
                    // meta: { role: ['apply_commission.update'] }
                },

                // ****************************** End apply-commission ***********************************************
                // ****************************** Begin reports-agents-dues ***********************************************
                {
                    path: "/reports/reports-agents-dues",
                    name: "reports-agents-dues.index",
                    component: () =>
                        import ("@/view/pages/modules/reports/agentsDues/Index.vue"),
                    // meta: { role: ['reports_agents_dues.list'] }
                },

                // ****************************** End reports-agents-dues ***********************************************
                // ****************************** Begin partners ***********************************************
                {
                    path: "/agents/partners",
                    name: "partners.index",
                    component: () =>
                        import ("@/view/pages/modules/agents/partners/Index.vue"),
                    // meta: { role: ['partners.list'] }
                },
                {
                    path: "/agents/partners/details/:id",
                    name: "partners.view",
                    component: () =>
                        import ("@/view/pages/modules/agents/partners/View.vue"),
                    // meta: { role: ['partners.list'] }
                },

                // ****************************** End partners ***********************************************
                // ****************************** Begin notifications-page *******************************************
                {
                    path: "/notifications",
                    name: "notifications.index",
                    component: () =>
                        import ("@/view/pages/modules/users/Notifications.vue"),
                    // meta: { role: ['notifications.list'] }
                },
                // ****************************** End notifications-page ***********************************************

                // ****************************** Begin offers ***********************************************
                {
                    path: "/subscription/offers",
                    name: "offers.index",
                    component: () =>
                        import ("@/view/pages/modules/subscription/offers/Index.vue"),
                    meta: { role: ['offers.list'] }
                },
                {
                    path: "/subscription/offers/create",
                    name: "offers.create",
                    component: () =>
                        import ("@/view/pages/modules/subscription/offers/Form.vue"),
                    meta: { role: ['offers.create'] }
                },
                {
                    path: "/subscription/offers/edit/:id",
                    name: "offers.update",
                    component: () =>
                        import ("@/view/pages/modules/subscription/offers/Form.vue"),
                    meta: { role: ['offers.update'] }
                },

                // ****************************** End offers ***********************************************
                // ****************************** Begin extension_requests ********************************************
                {
                    path: "/subscription/extension-requests",
                    name: "extension-requests.index",
                    component: () =>
                        import ("@/view/pages/modules/subscription/subscriptionExtensionRequests/Index.vue"),
                    // meta: { role: ['extension_requests.list'] }
                },
                // ****************************** End extension_requests **********************************************

                // ****************************** Begin extension_requests ********************************************
                {
                    path: "/subscription/request-services",
                    name: "request-services.index",
                    component: () => import ("@/view/pages/modules/subscription/requestServices/Index.vue"),
                    // meta: { role: ['request_services.list'] }
                },
                // ****************************** End extension_requests **********************************************

                // ****************************** Begin plans-discounts ***********************************************
                {
                    path: "/subscription/plans-discounts",
                    name: "plans-discounts.index",
                    component: () =>
                        import ("@/view/pages/modules/subscription/plansDiscounts/Index.vue"),
                    // meta: { role: ['plan_discounts.list'] }
                },
                {
                    path: "/subscription/plans-discounts/create/:plan_id",
                    name: "plans-discounts.create",
                    component: () =>
                        import ("@/view/pages/modules/subscription/plansDiscounts/Form.vue"),
                    // meta: { role: ['plan_discounts.create'] }
                },
                {
                    path: "/subscription/plans-discounts/edit/:id",
                    name: "plans-discounts.update",
                    component: () =>
                        import ("@/view/pages/modules/subscription/plansDiscounts/Form.vue"),
                    // meta: { role: ['plan_discounts.update'] }
                },

                // ****************************** End plans-discounts ***********************************************
                // ****************************** Begin Categories *******************************************
                {
                    path: "/settings/periods",
                    name: "periods.index",
                    component: () =>
                        import ("@/view/pages/modules/settings/periods/Form.vue"),
                    meta: { role: ['periods.update'] }
                },

                // ****************************** End Categories ***********************************************

            ]
        },
        {
            path: "/",
            component: () =>
                import ("@/view/pages/auth/login_pages/Login-1"),
            children: [{
                name: "login",
                path: "/login",
                component: () =>
                    import ("@/view/pages/auth/login_pages/Login-1")
            }, ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () =>
                import ("@/view/pages/error/Error-404.vue")
        },
        {
            // the 404 route, when none of the above matches
            path: "/403",
            name: "403",
            component: () =>
                import ("@/view/pages/error/Error-403.vue")
        }
    ]
});
route.beforeEach((to, from, next) => {
    const role = to.meta;
    const currentUserRoles = Permissions.getPermission();

    if (role && role.role) {
        let _role = role.role[0];

        if (!currentUserRoles.includes(_role)) {
            return next({ path: "/403" });
        }
    }
    next();
});
const DEFAULT_TITLE = i18n.t('header_title');
route.afterEach((to) => {
    document.title = DEFAULT_TITLE;
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});
export default route;