// Arabic
export const locale = {
    header_title: 'سجلها',
    TRANSLATOR: {
        SELECT: "اختر لغتك"
    },
    MENU: {
        NEW: "الجديد",
        ACTIONS: "الإجراءات",
        CREATE_POST: "إنشاء منشور جديد",
        PAGES: "الصفحات",
        FEATURES: "سمات",
        APPS: "تطبيقات",
        DASHBOARD: "لوحة التحكم",
        USERS: "المستخدمين",
        Roles_Management: 'إدارة الأدوار',
        users_roles_management: 'المستخدمين والأدوار',
        company_profile: 'ملف الشركة',
        change_email_password: 'تغيير البريد الالكتروني وكلمة المرور',
        user_Management: 'إدارة المستخدمين',
        Countries: 'الدول',
        setting_management: 'الإعدادات',
        cities_management: 'إدارة المدن',
        country_management: 'إدارة الدول',
        Cities: 'المدن',
        Categories: 'التصنيفات',
        module_settings: 'اعدادات الوحدات النمطية',
        module_settings_sub_actions: 'اعدادات الوحدات النمطية الإجراءات الفرعية',
        business_field: 'المجالات التجارية',
        business_field_management: 'إدارة المجالات التجارية',
        register_company_management: 'إدارة تسجيل الشركات',
        register_company: 'سجل الشركات',
        subscription: 'الاشتراكات',
        plans: 'الخطط',
        request_tenant_subdomain: 'طلبات الدومينات الفرعية',
        management_subdomain: 'إدارة الدومينات الفرعية',
        management_database: 'إدارة قواعد البيانات',
        required_tenants: 'طلبات المستأجرين',
        reports_subscriptions: 'تقارير الاشتراكات',
        reports: 'التقارير',
        payments_moyasar: 'دفعات Moyasar',
        payments_paypal: 'دفعات PayPal',
        payments_cash: 'الدفعات النقدية',
        agents_management: 'إدارة الوكلاء',
        agents: 'الوكلاء',
        commission_management: 'إدارة العمولات',
        agents_and_customers: 'الوكلاء والعملاء',
        apply_commission: 'تطبيق العمولة',
        reports_agents_dues: 'تقرير مستحقات الوكلاء',
        partners: 'الشركاء',
        all_notifications: 'جميع الإشعارات',
        offers: 'إدارة العروض',
        extension_requests: 'طلبات تمديد الإشتراكات',
        discounts: 'الخصومات',
        periods: 'الفترات',
        sync_endpoint : 'اعدادت Endpoint',
        reports_expired_subscriptions : 'تقارير الاشتراكات المنتهية',
        bank_transfers : 'التحويلات البنكية',
        admin_accounts : 'حسابات الادمن',
        request_services: 'طلبات الخدمات',
        invoices : 'فواتير الشركات',
        reset_companies : 'اعادة ضبط الشركات',

    },
    AUTH: {
        GENERAL: {
            OR: "Or",
            SUBMIT_BUTTON: "Submit",
            NO_ACCOUNT: "Don't have an account?",
            SIGNUP_BUTTON: "Sign Up",
            FORGOT_BUTTON: "Forgot Password",
            BACK_BUTTON: "Back",
            PRIVACY: "Privacy",
            LEGAL: "Legal",
            CONTACT: "Contact"
        },
        LOGIN: {
            TITLE: "Login Account",
            BUTTON: "Sign In"
        },
        FORGOT: {
            TITLE: "Forgot Password?",
            DESC: "Enter your email to reset your password",
            SUCCESS: "Your account has been successfully reset."
        },
        REGISTER: {
            TITLE: "Sign Up",
            DESC: "Enter your details to create your account",
            SUCCESS: "Your account has been successfuly registered."
        },
        INPUT: {
            EMAIL: "Email",
            FULLNAME: "Fullname",
            PASSWORD: "Password",
            CONFIRM_PASSWORD: "Confirm Password",
            USERNAME: "Username"
        },
        VALIDATION: {
            INVALID: "{{name}} is not valid",
            REQUIRED: "{{name}} is required",
            MIN_LENGTH: "{{name}} minimum length is {{min}}",
            AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
            NOT_FOUND: "The requested {{name}} is not found",
            INVALID_LOGIN: "The login detail is incorrect",
            REQUIRED_FIELD: "Required field",
            MIN_LENGTH_FIELD: "Minimum field length:",
            MAX_LENGTH_FIELD: "Maximum field length:",
            INVALID_FIELD: "Field is not valid"
        }
    },
    Do_you_want_delete_it: 'هل انتا متاكد من حذفه ',
    Confirm: 'تاكيد',
    Cancel: 'الغاء',
    ok: 'موافق',
    close: 'إغلاق',
    save: 'حفظ',
    cancel: 'الغاء',
    actions: 'الإجراءات',
    Something_went_wrong: 'هناك خطأ ما',
    Filter: 'فلتر',
    Search_query: 'البحث السريع',
    Search: 'بحث',
    Loading: 'تحميل',
    edit: 'تعديل',
    delete: 'حذف',
    back: 'رجوع',
    records: 'سجل',
    yes: 'نعم',
    no: 'لا',
    all: 'الكل',
    advanced_filter: 'فلتر متقدم',
    reset_search: 'إعادة تعيين البحث',
    main: 'رئيسي',
    others: 'اخرى',
    active: 'مفعل',
    inactive: 'غير مفعل',
    client: 'عميل',
    insurance_agent: 'وكيل تأمين',
    status: 'الحالة',
    is_default: 'اساسي',
    internal: 'داخلى',
    external: 'خارجي',
    created_at: 'انشئ في',
    upload_logo: 'تحميل شعار',
    add_more: 'اضف المزيد',
    from_date: 'من تاريخ',
    to_date: 'الى تاريخ',
    export: 'تصدير',
    excel: 'اكسل',
    generate_pdf: 'تصدير PDF',
    custom_export: 'تصدير مخصص',
    select_all: 'اختيار الكل',
    add_new: 'اضف جديد',
    change_status: 'تغيير الحالة',
    Hi: 'مرحباً',
    User_Profile: 'ملف تعريفي للمستخدم',
    Sign_out: 'تسجيل الخروج',
    not_selected: 'غير مختار',
    more_actions: 'إجراءات إضافية',
    commands: 'الأوامر',
    extra_options: 'خيارات إضافية',
    all_transactions: 'كل المعاملات',
    no_data_to_show: 'لا يوجد بيانات لعرضها',
    clone: 'نسخة',
    link: 'الرابط',
    finished: 'مكتمل',
    unfinished: 'غير مكتمل',
    Added_By: 'أضيفت بواسطة',
    unpaid: 'غير مدفوعة',
    paid: 'مدفوع',
    products: 'المنتجات',
    clients: 'العملاء',
    invoice: 'فاتورة',
    search: 'بحث',
    print_last_invoice: 'طباعة آخر فاتورة',
    session_validate: 'التحقق من صحة الجلسة',
    product_information: 'معلومات المنتج',
    payment: 'الدفع',
    add_client: 'إضافة عميل',
    payment_method: 'طريقة الدفع',
    payment_amount: 'مبلغ الدفع',
    payment_list: 'قائمة الدفع',
    discount: 'خصم',
    order_details: 'تفاصيل الطلب',
    cash_payment: 'دفع نقدي',
    from: 'من',
    amount: 'المبلغ',
    paid_by: 'المدفوع بواسطة',
    received_by: 'المستلمة بواسطة',
    number_no: 'رقم.',
    signature: 'التوقيع',
    do_you_want_make_it_as_draft: 'هل تريد جعله كمسودة؟',
    do_you_want_to_close_session: 'هل تريد إغلاق الجلسة؟',
    no_transactions_found: 'لم يتم العثور على معاملات',
    no_invoices_found: 'لم يتم العثور على فواتير',
    no_refund_receipts_found: 'لم يتم العثور على إيصالات استرداد',
    no_payments_found: 'لم يتم العثور على مدفوعات',
    pos_client: 'عميل نقاط البيع',
    screen: 'شاشة',
    print: 'طباعة',
    pdf: 'PDF',
    setup_installment_agreement: 'اتفاقية تقسيط الإعداد',
    create_credit_note: 'مذكرة الائتمان',
    assign_costs_centers: 'تعيين مراكز التكاليف',
    qty: 'الكمية',
    accounts: 'الحسابات',
    name: 'الاسم',
    type: 'النوع',
    count_invoice : 'عدد الفواتير',
    invoice_code : 'رقم الفاتورة',
    user: 'المستخدم',
    notes: 'ملاحظات',
    abbreviation: 'الاختصار',
    salesInvoice: 'فواتير المبيعات',
    purchaseInvoice : 'فواتير المشتريات',
    email: 'البريد الالكتروني',
    key: 'مفتاح',
    category: 'الفئة',
    date: 'التاريخ',
    customer: 'العميل',
    paginationLimit: 'السجلات',
    paginationCount: 'عدد',
    paginationFirst: 'أولاً',
    paginationLast: 'آخر',
    paginationFilter: 'الفلتر',
    paginationPage: 'صفحة',
    paginationNoResult: 'لا يوجد نتيجة',
    paginationFilterBy: 'التصنيف بواسطة',
    paginationLoading: 'جار التحميل',
    paginationDefaultOption: 'الخيار الافتراضي',
    No_sub_options: 'لا توجد خيارات فرعية.',
    No_options_available: 'لا توجد خيارات متاحة.',
    No_results_found: 'لم يتم العثور على نتائج...',
    Select: 'اختر...',
    added_by: 'أضيفت بواسطة',
    currency: 'العملة',
    view: 'عرض',
    mark_as_draft: 'تحديد كمسودة',
    add_payment: 'اضافة دفع',
    options: 'خيارات',
    total: 'المجموع',
    quantity: 'الكمية',
    price: 'السعر',
    confirm: 'تأكيد',
    customer_search: 'البحث عن الزبائن',
    customer_name: 'اسم الزبون',
    start_session: 'بدأ الجلسة',
    create_invoice: 'انشاء فاتورة',
    pos_session_n: 'جلسة نقطة البيع',
    invoices: 'الفواتير',
    clients_n: 'الزبائن',
    new_client: 'زبون جديد',
    sales: 'المبيعات',
    payments_summary: 'ملخص المدفوعات',
    profit_and_loss: 'الربح والخسارة',
    income_and_expenses: 'الدخل والنفقات',
    recent_invoices: 'الفواتير الحديثة',
    overdue_invoices: 'الفواتير المتأخرة',
    payment_statistics: 'إحصائيات الدفع',
    invoice_search: 'البحث عن فاتورة',
    invoice_number: 'رقم الفاتورة',
    invoice_date: 'تاريخ الفاتورة',
    recent_payments: 'المدفوعات الحديثة',
    low_stock_products: 'المنتجات منخفضة المخزون',
    last_appointments: 'آخر المواعيد',
    view_all: 'مشاهدة الكل',
    activity_logs: 'سجلات النشاط',
    num_movements: 'عدد الحركات',
    available: 'متاح',
    out_of_stock: 'نفذت الكمية',
    expense: 'النفقات',
    income: 'الدخل',
    refund: 'المرتجع',
    low_of_stock: 'الكمية منخفضة',
    to: 'الى',
    details: 'التفاصيل',
    daily: 'يومي',
    weekly: 'أسبوعي',
    monthly: 'شهريا',
    yearly: 'سنوي',
    employee: 'الموظف',
    select_filter: 'اختر فلتر',
    revenue_invoice: 'إيرادات الفواتير',
    collected_by: 'جمع بواسطة',
    supplier: 'المورد',
    brand: 'العلامة التجارية',
    product: 'المنتج',
    show_draft: 'عرض المسودة',
    staff: 'الموظف',
    daily_products: 'المنتجات اليوميه',
    summery: 'ملخص',
    time: 'الوقت',
    group_by: 'تجميع حسب',
    due_date: 'تاريخ الاستحقاق',
    order_by: 'ترتيب حسب',
    asc: 'تصاعدي',
    desc: 'تنازلي',
    partial_paid: 'مدفوعة جزئيا',
    overdue: 'متأخر',
    summary: 'الملخص',
    prefix: 'البادئة',
    digits: 'الأرقام',
    phone: 'الهاتف',
    enter: 'ادخل',
    please_upload_valid_file: 'الرجاء تحميل ملف صحيح',
    load_more: 'إظهار المزيد',
    create: 'إنشاء',
    update: 'تعديل',
    arabic: 'العربية',
    english: 'الإنجليزية',
    copied: 'تم نسخ',
    copy: 'نسخ',
    referral_link: 'رابط الوكيل',
    generate_key: 'توليد مفتاح',
    make_all_read: 'جعل الكل مقروء',
    unread_notifications: 'إشعار غير مقروء',
    global: {
        all: 'اختر',
        actions: 'الاختيارات',
        yes: 'نعم',
        no: 'لا',
    },
    header: {
        my_profile: 'صفحتي الشخصية',
        account_settings_and_more: 'إعدادات الحساب والمزيد',
        change_email_password: 'تغيير البريد الالكتروني وكلمة المرور',
    },
    roles: {
        roles_management_section: 'قسم ادارة الادوار',
        role_name: 'اسم الدور',
        description: 'الوصف',
        status: 'الحالة',
        created_at: 'تاريخ الإنشاء',
        new_role: 'دور جديد',
        role: 'دور',
        edit_role: 'تعديل الدور',
    },
    users: {
        users_management: 'قسم إدارة المستخدمين',
        users: 'المستخدمين',
        user_name: 'اسم المستخدم',
        name: 'الاسم',
        phone: 'الهاتف',
        email: 'البريد الإلكتروني',
        password: 'كلمة المرور',
        status: 'الحالة',
        created_at: 'تاريخ الإنشاء',
        new_user: 'مستخدم جديد',
        user: 'مستخدم',
        edit_user: 'تعديل المستخدم',
        roles: 'الأدوار',
        description: 'الوصف',
        first_name: 'الاسم الأول',
        last_name: 'الاسم الأخير',
        countries: 'الدول',
        cities: 'المدن',
        confirm_password: 'تأكيد كلمة المرور',
        active: 'نشط',
        inactive: 'غير نشط',
        add_user: 'إضافة مستخدم',
        pl_first_name: 'ادخل الاسم الاول',
        pl_last_name: 'ادخل اسم الاخير',
        pl_email: 'ادخل البريد الإلكتروني',
        pl_password: 'ادخل كلمة المرور',
        pl_confirm_password: 'ادخل تأكيد كلمة المرور',
        pl_phone: 'ادخل رقم الهاتف',
        pl_roles: 'اختر الادوار',
        pl_username: 'ادخل اسم المستخدم',
    },
    user_settings: {
        change_email_password: 'تغيير البريد الالكتروني وكلمة المرور',
        change_email: 'تغيير البريد الالكتروني',
        current_password: 'كلمة المرور الحالية',
        email: 'البريد الالكتروني',
        new_password: 'كلمة المرور الجديدة',
        confirm_password: 'تأكيد كلمة المرور',
        change_password: 'تغيير كلمة المرور',
    },
    company: {
        company_profile: 'ملف الشركة',
        basic_information: 'معلومات اساسية',
        business_name: 'الاسم التجاري',
        business_type: 'نوع العمل',
        currency: 'عملة',
        language: 'لغة',
        time_zone: 'وحدة زمنية',
        date_format: 'صيغة التاريخ',
        printing_method: 'طريقة الطباعة',
        upload_logo: 'تحميل الشعار',
        address_information: 'معلومات العنوان',
        country: 'دولة',
        city: 'مدينة',
        postal_code: 'رمز بريدي',
        district: 'يصرف',
        address_1: 'العنوان 1',
        address_2: 'العنوان 2',
        mobile: 'التليفون المحمول',
        phone: 'هاتف',
        email: 'البريد الإلكتروني',
        first_name: 'الاسم الاول',
        last_name: 'الاسم الأخير',
        contact_information: 'معلومات التواصل',
    },
    login_page: {
        Discover_Amazing_Sajlha_with_great_build_tools: "اكتشف سجلها المدهش <br /> مع أدوات بناء رائعة",
        Welcome_to_Sajlha: 'مرحبا بكم في سجلها',
        Email: 'البريد الإلكتروني',
        Password: 'كلمة المرور',
        Sign_In: 'تسجيل الدخول',
        Sign_Up: 'اشتراك',
        Enter_your_details_to_create_your_account: 'أدخل التفاصيل الخاصة بك لإنشاء حسابك',
        Submit: 'يُقدِّم',
        Cancel: 'يلغي',
        Forgotten_Password: 'كلمة سر منسية ؟',
        Enter_your_email_to_reset_your_password: 'أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك',
        Terms: 'شروط',
        Plans: 'الخطط',
        Contact_Us: 'اتصل بنا',
        Username_is_required: 'اسم المستخدم مطلوب',
        Password_is_required: 'كلمة المرور مطلوبة',
        Full_name_is_required: 'الإسم الكامل ضروري',
        Email_is_required: 'البريد الالكتروني مطلوب',
        The_value_is_not_a_valid_email_address: 'القيمة ليست عنوان بريد إلكتروني صالحًا',
        Confirm_password_is_required: 'تأكيد كلمة المرور مطلوب',
        The_password_and_its_confirm_are_not_the_same: 'كلمة المرور وتأكيدها ليسا متطابقين',
        You_should_agree_terms_and_conditions: 'يجب أن توافق على الشروط والأحكام',
        Please_provide_correct_data: 'من فضلك ، قدم البيانات الصحيحة!',
    },
    errors: {
        the_page_not_found: 'الصفحة غير موجودة',
        you_dont_have_permission: 'ليس لديك صلاحية',
    },
    countries: {
        country_management: 'إدارة الدولة',
        countries: 'بلدان',
        country: 'الدولة',
        phone_code: 'كود الهاتف',
        en_name: 'الاسم الانجليزي',
        ar_name: 'الاسم العربي',
        code2: 'الرمز 2',
        code3: 'الرمز 3',
    },
    cities: {
        new_city: 'مدينة جديدة',
        cities: 'المدن',
        city_management: 'قسم إدارة المدن',
        city: 'المدينة',
        en_name: 'الاسم بالانجليزية',
        ar_name: 'الاسم بالعربية',
        code2: 'الكود 2',
        code3: 'الكود 3',
    },
    categories: {
        categories: 'التصنيفات',
        add_new_category: 'اضافة تصنيف جديد',
        add_new_sub_category: 'اضافة تصنيف فرعي جديد',
        name: 'الاسم',
        description: 'الوصف',
        upload_image: 'تحميل صورة',
    },

    module_settings: {
        new_module_setting: 'اضافة اعداد الوحدة النمطية',
        module_name: 'اسم الوحدة النمطية',
        date: 'التاريخ',
        user: 'المستخدم',
        general_name: 'الاسم العام',
        module_settings: 'اعدادات الوحدات النمطية',
        summery: 'الملخص',
        details: 'التفاصيل',
        upload_image: 'تحميل صورة',
        title: 'العنوان',
        new_sub_action: 'اجراء فرعي جديد',
        sub_actions: 'الاجراءات الفرعية',
        multi_images: 'صور متعددة',
        image: 'صورة',
        priority: 'الأولوية',
    },

    business_fields: {
        business_field: 'مجال العمل',
        new_business_field: 'اضافة مجال جديد',
        plan: 'الخطة',
        type: 'النوع',
    },


    register_company: {
        add_new_account: 'انشاء حساب جديد',
        just_two_minutes_to_get_started: 'دقيقتين فقط لبدء الاستخدام',
        trade_name: 'الاسم التجاري',
        name: 'الاسم',
        email: 'البريد الإلكتروني',
        login_page: 'صفحة الدخول',
        password: 'كلمة المرور',
        confirm_password: 'تأكيد كلمة المرور',
        what_you_need_to_run_your_business_in_one_program: 'ما تحتاجه لادارة اعمالك في برنامج واحد',
        get_started_for_free: 'ابدأ الإستخدام مجانا',
        all_of_these_features_are_customized_to_your_industry: 'كل هذه الميزات مخصصة حسب مجال عملك',
    },
    sync_endpoint: {
        name: 'الاسم',
        end_point: 'End point',
        method: 'Method',
        type: 'النوع',
        description: 'الوصف',
        from: 'من',
        to: 'إلى',
    },
    plans: {
        name: 'الاسم',
        modules_used: 'المديول المضمنة',
        date: 'تاريخ انشاء الخطة',
        price_per_month: 'سعر الخطة الشهري',
        price_per_year: 'سعر الخطة السنوي',
        currency: 'العملة',
        plan_type: 'نوع الخطة',
        number_company_branches: 'عدد أفرع الشركة',
        number_company_inventory: 'عدد مخازن الشركة',
        number_pos: 'عدد نقاط البيع',
        number_employee: 'عدد الموظفين',
        number_customers: 'عدد  العملاء',
        number_items: 'عدد المنتجات',
        new_plan: 'خطة جديدة',
        from_date: 'من تاريخ',
        priority : 'الترتيب',
        to_date: 'الى تاريخ',
        user: 'المستخدم',
        plans: 'الخطط',
        details: 'التفاصيل',
        free_plan: 'الخطة أساسية',
        available_days: 'الايام المتاحة',
        permissions: 'الصلاحيات',
        number_users: 'عدد المستخدمين',
        number_sales_invoices: 'عدد فواتير المبيعات',
        price_per_3_month: 'سعر الخطة ل 3 شهور',
        price_per_6_month: 'سعر الخطة ل 6 شهور',
        notes: 'ملاحظات',
        unlimited: 'لا محدود',
        offer_name: 'اسم العرض',
        offer_date: 'تاريخ العرض',
        offer_free_days: 'عدد الايام المجانية للعرض',
        offers_applied: 'العروض المطبقة',
        offers_log: 'سجل العروض',
        plan_services: 'خدمات الخطة',
        additional_pos_price: 'سعر نقطة بيع اضافية',
        additional_branch_price: 'سعر فرع اضافي',
        extension_days: 'عدد أيام التمديد',
        additional_user_price: 'سعر مستخدم اضافي',
        maximum_amount_sales: 'الحد الاقصى لقيمة المبيعات',
        is_free_trial: 'هل مسموح بالتجربة المجانية',
        free_trial_days: 'عدد أيام التجربة المجانية',
        is_custom_program: 'برنامج مخصص',
        basic_features: 'الميزات الأساسية',
        title: 'العنوان',
        summary: 'الملخص',
        print_setting_details : 'تفاصيل اعدادات الطباعة',
        invoices_bond_configuration : 'إعدادات الفواتير والسندات',
        doc_type : 'نوع الوثيقة',
        code_settings : 'اعدادات الكود',
        print_setting_management : 'اعدادات الطباعة',
        invoices_bonds_management : 'إعدادات الفواتير والسندات',
        code_settings : 'اعدادات الكود',
        service_type : 'نوع الخدمة',
        price : 'السعر',
        branch_price : 'سعر الفرع',
        user_price : 'سعر المستخدم',
        pos_price : 'سعر نقطة بيع',
        subscription_extension_for_expired_companies : 'تمديد الاشتراك للشركات المنتهية',
    },
    subdomain: {
        account_no: 'رقم الحساب',
        db_flag: 'حالة قاعدة البيانات',
        subdomain_flag: 'حالة الدومين الفرعي',
        db_type: 'نوع قاعدة البيانات',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        details: 'التفاصيل',
        subdomain: 'الدومين الفرعي',
        uuid: 'UUID',
        attachment: 'المرفق',
        link: 'الرابط',
        add_tenant: 'اضف مستأجر',
        amount: 'المبلغ',
        subscription_type: 'نوع الاشتراك',
        monthly: 'شهري',
        yearly: 'سنوي',
        change_expire_date: 'تعديل تاريخ الانتهاء',
        upgrade_plan: 'ترقية الخطة',
        add_payment: 'إنشاء دفعة  نقدية',
        expire_date: 'تاريخ الانتهاء',
        plan : 'الخطة',
        permission_management : 'ادارة الصلاحيات',
        print_setting_management : 'اعدادات الطباعة',
        invoices_bonds_management : 'إعدادات الفواتير والسندات',
        code_settings : 'اعدادات الكود',
        update_inventory_tracking_statistics : 'تحديث تتبع احصائيات المخزون',
        update_qty_price_inventory_tracking_statistics : 'تحديث الكميات والاسعار لتتبع احصائيات المخزون',
    },
    activity_log: {
        basic_information: 'المعلومات الاساسية',
        activity_log : 'سجل النشاطات',
    },
    reset_company: {
        company : 'الشركة',
    },
    management_subdomain: {
        assign_account_no: 'رقم الحساب',
        business_name_subdomain: 'الاسم التجاري',
        available_flag: 'حالة التفعيل',
        notes: 'ملاحظات',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',

        db_name: 'قاعدة البيانات',
        business_name: 'اسم الشركة',
        expire_date: 'تاريخ الانتهاء',
        plan_name: 'الخطة',
        subscription_information: 'بيانات الإشتراك',
        first_name: 'الاسم الاول',
        last_name: 'الاسم الاخير',
        email: 'البريد الالكتروني',
        country: 'الدولة',
        city: 'المدينة',
        domain : 'الدومين',
        address1: 'العنوان 1',
        address2: 'العنوان 2',
        start_date: 'تاريخ بدأ الاشتراك',
        end_date: 'تاريخ انتهاء الاشتراك',
        phone: 'الهاتف',
        company_name: 'اسم الشركة',
        company: 'الشركة',
        active_from_date: 'الحساب الفعال من تاريخ',
        active_to_date: 'الحساب الفعال الى تاريخ',
        expired_account: 'الحسابات المنتهية',

        users_count: 'عدد المستخدمين',
        items_count: 'عدد المنتجات',
        customers_count: 'عدد العملاء',
        suppliers_count: 'عدد الموردين',
        sales_count: "عدد المبيعات",
        sales_total: "مجموع المبيعات",
        purchases_count: "عدد المشتريات",
        purchases_total: "مجموع المشتريات",
        payment_count: "عدد الدفعات",
        payment_total: "مجموع الدفعات",
        items_count: "عدد المنتجات",
        sales_last_sales_date: "تاريخ اخر فاتورة مبيعات",
        purchases_last_purchases_date: "تاريخ اخر فاتورة مشتريات",
        reports_companies_invoices: "تقرير فواتير الشركات",
    },
    management_database: {
        assign_account_no: 'تعيين رقم الحساب',
        name_db: 'اسم قاعدة البيانات',
        user_name_db: 'اسم مستخدم قاعدة البيانات',
        password_db: 'كلمة مرور قاعدة البيانات',
        available_flag: 'العلم المتاح',
        notes: 'ملاحظات',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        databases_without_companies: 'قواعد بيانات بدون شركات',
    },
    required_tenant: {
        assign_account_no: 'تعيين رقم الحساب',
        available_flag: 'العلم المتاح',
        notes: 'ملاحظات',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
    },
    reports_subscriptions: {
        assign_account_no: 'تعيين رقم الحساب',
        business_name_subdomain: 'المجال الفرعي لاسم النشاط التجاري',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        plan_name: 'الخطة',
        first_name: 'الاسم الأول',
        last_name: 'اسم الاخير',
        phone: 'الهاتف',
        email: 'البريد إلكتروني',
        payment_type: 'نوع الدفع',
        postal_code: 'رمز بريدي',
        subscription_price: 'مبلغ الاشتراك',
        start_date: 'تاريخ البدء',
        end_date: 'تاريخ الانتهاء',
        reports_subscriptions: 'تقارير الاشتراكات',
        company: 'الشركة',
        subscription_type: 'نوع الاشتراك',
    },
    statistics: {
        total_number_of_clients: 'عدد العملاء الكلي',
        the_number_of_customers_on_the_free_plan: 'عدد العملاء على الخطة المجانية',
        The_number_of_clients_on_the_first_plan: 'عدد العملاء على الخطة الأولى',
        the_number_of_customers_on_the_second_plan: 'عدد العملاء على الخطة الثانية',
        total_amounts_for_the_last_year: 'المبالغ الكلية لآخر سنة',
        total_amounts: 'المبالغ الكلية',
        the_number_of_customers_suspended_due_to_payment: 'عدد  العملاء المتوقفة بسبب الدفع',
        number_of_plans: 'عدد الخطط',
        more_plan_number_of_users: 'أكثر خطة عدد مستخدمين',
        minimum_number_of_users: 'أقل خطة عدد مستخدمين',
        the_expected_monthly_amount_of_payment: 'المبلغ الشهري المتوقع للدفع',
        the_number_of_accounts_that_pay_an_annual_subscription: 'عدد الحسابات التي تدفع باشتراك سنوي',
        the_number_of_accounts_the_first_plan_a_monthly_subscription: 'عدد الحسابات خطة اولى اشتراك شهري',
        the_number_of_accounts_is_a_second_plan_a_monthly_subscription: 'عدد الحسابات خطة ثانية اشتراك شهري',
        number_of_accounts_third_plan_monthly_subscription: 'عدد الحسابات خطة ثالثة اشتراك شهري',
        the_number_of_accounts_is_the_first_annual_subscription_plan: 'عدد الحسابات خطة اولى اشتراك سنوي',
        the_number_of_accounts_is_a_second_plan_an_annual_subscription: 'عدد الحسابات خطة ثانية اشتراك سنوي',
        number_of_accounts_third_plan_annual_subscription: 'عدد الحسابات خطة ثالثة اشتراك سنوي',

    },

    payments: {
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        payer_id: 'معرف الدافع',
        payment_method: 'طريقة الدفع',
        amount: 'المبلغ',
        currency: 'العملة',
        payment_id: 'معرف عملية الدفع',
    },
    agents: {
        name: 'الاسم',
        full_name: 'الاسم كامل',
        email: 'البريد الإلكتروني',
        mobile: 'الموبايل',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        country: 'الدولة',
        user_agent: 'المستخدم الوكيل',
        no_of_customer: 'عدد العملاء',
        created_at: 'أنشئت في',
        total_sales_amount: 'إجمالي مبلغ المبيعات',
        add_agent: 'اضف وكيل',
        first_name: 'الاسم الأول',
        middle_name: 'الاسم الأوسط',
        last_name: 'اسم العائلة',
        phone: 'الهاتف',
        nationality: 'الجنسية',
        default_language: 'اللغة الافتراضية',
        supported_language: 'دعم اللغة',
        supported_country: 'دعم الدول',
        supported_filed: 'مجالات العمل',
        currency: 'العملة',
        is_general_country: 'هل الدولة عامة',
        is_general_field: 'هل المجال عام',
        discount :'نسبه الخصم',
    },
    commission: {
        agent_name: 'اسم الوكيل',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        from_commission: 'من العمولة',
        to_commission: 'الى العمولة',
        from_discount: 'من الخصم',
        to_discount: 'الى الخصم',
        applied_date: 'تاريخ التطبيق',
        discount_rate: 'نسبة الخصم',
        commission_rate: 'نسبة العمولة',
        general_interval: 'الفترة الزمنية العامة',
        add_commission: 'أضف عمولة',
        month: 'شهر',
        year: 'سنة',
        commission: 'العمولة',
    },
    agents_customers: {
        agent_name: 'اسم الوكيل',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        client_name: 'اسم العميل',
        start_date: 'تاريخ البداية',
        end_date: 'تاريخ الانتهاء',
        no_of_payment: 'عدد المدفوعات',
        from_rate: 'من نسبة العمولة',
        to_rate: 'الى نسبة العمولة',
        total_payment: 'إجمالي المدفوعات',
    },
    apply_commission: {
        add_apply_commission: 'اضف تطبيق عمولة',
        agent_name: 'الوكيل',
        customer_name: 'العميل',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        amount: 'المبلغ',
        pay: 'دفع',
        pay_for_agent: 'دفع للوكيل',
        payment_method: 'طريقة الدفع',
        payment_notes: 'ملاحظات الدفع',
        paid: 'مدفوع',
    },
    payment_methods: {
        credit_card: 'بطاقة إئتمان',
        paypal: 'باي بال',
        cash: 'نقدي',
        bank_transfer: 'التحويل البنكي',
    },
    reports_agents_dues: {
        agent_name: 'اسم الوكيل',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        received_amount: 'المبلغ المستلم',
        remaining_amount: 'المبلغ المتبقي',
        total_amount: 'المبلغ الكلي',
    },
    partners: {
        full_name: 'الاسم كامل',
        email: 'البريد الإلكتروني',
        mobile: 'الجوال',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        country: 'الدولة',
        created_at: 'انشئ في',
        fields: 'مجالات',
        phone: 'الهاتف',
        individual: 'فردي',
        company: 'شركة',
        company_name: 'اسم الشركة',
        business_number: 'رقم الشركة',
        city: 'المدينة',
        company_type: 'نوع الشركة',
        details: 'التفاصيل',

    },
    notifications: {
        user_notifications: 'إشعارات المستخدم',
        no_new_notifications: 'لا يوجد اشعارات جديدة',
    },

    offers: {
        add_offer: 'أضف عرض',
        name: 'الاسم',
        users_number: 'عدد المستخدمين',
        percentage: 'نسبة الخصم',
        start_date: 'تاريخ البدء',
        end_date: 'تاريخ الانتهاء',
        notes: 'الملاحظات',
        is_active: 'الحالة',
        plans: 'الخطط',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        plan: 'الخطة',
        interval: 'الفترة',
        free_days: 'الأيام المجانية',

    },

    extension_requests: {
        user_name: 'اسم المستخدم',
        business_name_subdomain: 'المجال الفرعي لاسم النشاط التجاري',
        business_name: 'اسم الشركة',
        plan_name: 'الخطة',
        subscription_date: 'تاريخ الإشتراك',
        expire_date: 'تاريخ الانتهاء',
        last_amount_paid: 'آخر مبلغ مدفوع',
        request_date: 'تاريخ الطلب',
        days_number: 'عدد الأيام',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        reasons: 'الأسباب',
        registration_date: 'تاريخ التسجيل',
    },

    request_services: {
        service_type_name : 'نوع الخدمة',
        quantity : 'الكمية',
        price : 'السعر',
        notes : 'ملاحظات',
    },

    discounts: {
        add_discount: 'أضف خصم',
        name: 'الاسم',
        number_users: 'عدد المستخدمين',
        percentage: 'نسبة الخصم',
        start_date: 'تاريخ البدء',
        end_date: 'تاريخ الانتهاء',
        notes: 'الملاحظات',
        is_active: 'الحالة',
        plans: 'الخطط',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        plan: 'الخطة',
        interval: 'الفترة',
        applied_interval: 'الفترة المطبقة',
    },
    periods: {
        periods: 'الفترات',
        number: 'الرقم',
        period: 'الفترة',
        is_active: 'الحالة',
    },
    admin_account: {
        edit: 'تعديل حسابات الادمن',
        // company_name: 'اسم الشركة',
        email: 'البريد الإلكتروني',
        password: 'كلمة المرور',
    }
}